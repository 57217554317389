import utils from 'utils';

import {useState, useContext, useEffect} from 'react';

import {UiSelect} from '@components/export';
import {useMachineFilter, useLabel} from '@/hooks/export';

import appContext from '@/appContext';

export const FilterMachineSelect = (props: {
    handle: any,
}) => {
    const label = useLabel();

    const {machine, setMachine} = useContext(appContext).productsContext;
    const {brands, models} = useMachineFilter(machine.brand);

    const [isVisible, setIsVisible] = useState(false);
    const [purposeOptions, setPurposeOptions] = useState([]);

    const defaultPurposeOptions = [
        {value: 'front', label: label.sideFront},
        {value: 'back', label: label.sideBack},
    ];

    const data = {
        options: {
            brand: [],
            model: [],
            purpose: [],
        },
        purposeOptions,
    };

    const handle = {
        selectMachine(field) {
            machine[field.name] = field.value;

            setMachine({...machine});
        },
        toggleVisible() {
            setIsVisible(!isVisible);
        },
        cleanMachine() {
            props.handle.cleanFilters();
        },
    };

    if (brands !== undefined) {
        brands.map((brand) => {
            data.options.brand.push({value: brand, label: brand});
        });
    }

    if (models.length) {
        models.map((model) => {
            data.options.model.push({value: model, label: model});
        });
    }

    useEffect(() => {
        if (machine.brand && machine.model) {
            props.handle.selectMachine()
                .then((attributes) => {
                    const purposesExist = new Set(attributes.map((item) => item.purpose));
                    const toOptions = [];

                    purposesExist.forEach((item) => {
                        toOptions.push(
                            defaultPurposeOptions.find((option) => option.value === item),
                        );
                    });

                    data.options = {
                        ...data.options,
                        purpose: toOptions,
                    };

                    setPurposeOptions(toOptions);
                });
        } else if (machine.purpose !== '') {
            setPurposeOptions([]);
        }
    }, [machine]);

    return <div className={cmpClass}>
        <div className={classMap.actions}>
            <button
                onClick={() => handle.toggleVisible()}
                className={isVisible ? 'active' : ''}
            >
                {label.showSelect}
            </button>
            {
                machine.brand && machine.model ?
                    <span onClick={() => handle.cleanMachine()}>
                        <i className={'iconfa-close'}></i>
                    </span> :
                    <></>
            }
        </div>
        {
            isVisible ?
                [
                    getSelectJSX('brand', machine, handle, label, data),
                    getSelectJSX('model', machine, handle, label, data),
                    getSelectJSX('purpose', machine, handle, label, data),
                ] :
                <></>
        }
        {
            isVisible ?
                <div className={classMap.infoBox}>
                    <i className={'iconfa-info'}></i>
                    <p>{'Databáze je jen informativní, prosím zkontrolujte rozměr fyzicky na vozíku.'}</p>
                </div> : <></>
        }
    </div>;
};

const getSelectJSX = (
    field: string,
    machine: {brand: string, model: string, purpose: string},
    handle: any,
    label: any,
    data: any,
) => {
    const value = machine[field];
    const options = [
        {value: '', label: '-- ' + label.empty + ' --'},
    ]
        .concat(field === 'purpose' ? data.purposeOptions : data.options[field])
        .sort((a, b) => a.value > b.value ? 1 : -1);

    const onChange = (value: string) => {
        handle.selectMachine({
            name: field,
            value,
        });
    };

    return <UiSelect
        name={field}
        label={label[field]}
        value={value}
        options={options}
        onChange={onChange}
        key={field}
    />;
};

const cmpClass = 'filter-machine-select';

const classMap = utils.generateClassMap(cmpClass, {
    item: 'item',
    select: 'select',
    option: 'option',
    actions: 'actions',
    infoBox: 'info-box',
});
