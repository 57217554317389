import utils from 'utils';

import {useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import {useQueryClient} from '@tanstack/react-query';
import toast from 'react-hot-toast';

import {CartListItem, CartListServices} from '@components/export';
import {useLabel} from '@/hooks/export';

import {Skeleton} from '@mui/material';

import emptyImg from '@img/test1.jpg';
import appContext from '@/appContext';
import {api} from "@/api";

export const CartList = (props: {
    readonly?: boolean,
}) => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const label = useLabel();

    const cartContext = useContext(appContext).cartContext;

    const data = {
        products: [],
    };

    const handle = {
        dropItem(id: string) {
            api.cart.updateDelivery({id: '', optionId: '', selected: false})
                .then(() => this.updateItem(id, 0, []));
        },
        updateItem(
            id: string,
            qty: number,
            additionalServices: [additionalService], // fixme: you must rename it to singular
            additionalInfo: additionalInfo,
        ) {
            toast.remove();
            toast.loading(label.loading);

            return cartContext.updateItem(id, qty, additionalServices, additionalInfo)
                .then(() => {
                    queryClient.invalidateQueries({queryKey: ['cart']})
                        .then(() => {
                            toast.remove();
                            toast.success(label.cartIsUpToDate);
                        });
                });
        },
        navigateToProduct(id: string) {
            navigate(`/product/${id}`);
        },
        addProductOptionsImage(productId: string, attachment: machineRequestAttachment) {
            cartContext.addProductOptionsImage(productId, attachment);
        },
    };

    if (cartContext.cart && cartContext.cart.list) {
        Object.entries(cartContext.cart.list).map((item: [string, apiProduct]) => {
            const id = item[0];
            const product = item[1];

            data.products.push({
                id,
                code: product.code,
                price: {
                    current: product.priceCurrent,
                    prev: product.pricePrev,
                },
                info: {
                    stock: product.stock,
                    stockInfo: product.stockInfo,
                    isDiscount: product.isDiscount,
                },
                img: (product?.images && product.images[0]) ? utils.getApiImgPath(product.images[0], 'small') : emptyImg,
                title: product.name,
                qty: product.qty,
                additionalServices: product.additionalServices,
                additionalInfo: product.additionalInfo,
                message: product.message,
                hideQty: product.hideQty,
                category: product.category,
                measurement: product.measurement,
            });
        });
    }

    return <div className={cmpClass}>
        {
            cartContext.cart === undefined ?
                <Skeleton
                    height={'100%'}
                    variant={'rectangular'}
                    className={'cart-list-item'}
                /> :
                data.products.length ?
                    <>
                        {
                            data.products.map((product) => {
                                const productOptionsImages = cartContext.productOptionsImages[product.id] || [];

                                return <div className={classMap.itemBox} key={product.id}>
                                    <CartListItem
                                        handle={handle}
                                        product={product}
                                        readonly={props.readonly}
                                        productOptionsImages={productOptionsImages}
                                    />
                                    {
                                        (product?.additionalServices && Object.entries(product.additionalServices).length) ?
                                            <>
                                                <CartListServices
                                                    product={product}
                                                    handle={handle}
                                                    readonly={props.readonly}
                                                />
                                            </> : <></>
                                    }
                                </div>;
                            })
                        }
                    </> : <p>{label.noProducts}</p>
        }
    </div>;
};

const cmpClass = 'cart-list';

const classMap = utils.generateClassMap(cmpClass, {
    itemBox: 'item-box',
});
