import {
    CatalogInfo,
    CompanyBanner,
    Footer,
    Header,
    UserForm,
    UserRegister,
    PwaInstallPrompt,
} from '@components/export';

export const UserFormPage = (props: {
    type: string
}) => {
    return <>
        <Header/>

        <PwaInstallPrompt />

        <div className="page-content user-form-page">
            {
                props.type !== 'register' ?
                    <div className="user-form-page-container container">
                        <CompanyBanner/>
                        <UserForm type={props.type}/>
                    </div> :
                    <div className="user-form-page-register container">
                        <UserRegister/>
                    </div>
            }
        </div>


        <div className="user-form-page-info container">
            {/* NOTE: Custom login screen message */}
            <p>
                <span className='iconfa-info' /> Soutěž pro B2B o hodnotné ceny! Nakup a vyhraj. <a href="https://www.cemat.cz/cz/novinky/soutez-pro-b2b-o-hodnotne-ceny!-nakupujte-a-vyhrajte/135.html" target='_blank'>Pravidla soutěže</a>
            </p>
            <CatalogInfo />
        </div>

        <Footer/>
    </>;
};
