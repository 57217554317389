import utils from 'utils';

import {useQueryClient} from '@tanstack/react-query';

import {CountInput, UiCheckbox} from '@components/export';
import {useLabel} from '@/hooks/export';
import {api} from "@/api";

export const CartListServices = (props: {
    product: cartProduct,
    handle: any,
    readonly?: boolean,
}) => {
    const label = useLabel();
    const queryClient = useQueryClient();

    return <div className={cmpClass}>
        {
            Object.entries(props.product.additionalServices).map((apiService) => {
                const [serviceId, service] = apiService;

                const priceByQty = service.qty ? service.price * service.qty : service.price;

                const isPriceZero = utils.formatPrice(priceByQty) === '0,00';

                return (
                    !service.qty && props.readonly ?
                        <></> :
                        <div className={classMap.row} key={service.id}>
                            <UiCheckbox
                                onChange={() => {
                                    if (!props.readonly) {
                                        props.handle.updateItem(
                                            props.product.id,
                                            props.product.qty,
                                            [{id: serviceId, qty: service.qty ? 0 : props.product.qty}],
                                        )
                                            .then(() => {
                                                api.cart.updateDelivery({id: '', optionId: '', selected: false})
                                                    .then(() => {
                                                        queryClient.invalidateQueries({queryKey: ['cart']});
                                                    })
                                            })
                                    }
                                }}
                                label={service.name}
                                checked={!!service.qty}
                            />
                            <div className={classMap.box + (props.readonly ? ' readonly' : '')}>
                                <div className={classMap.item}>
                                    <span className={classMap.price}>
                                        <span className={service.qty ? '' : 'inactive'}>
                                            {
                                                !isPriceZero ?
                                                    utils.formatPrice(priceByQty) + ' ' + label.currency :
                                                    '-,--'
                                            }
                                        </span>
                                        {
                                            service.qty > 1 ?
                                                <span className={'secondary'}>
                                                    {
                                                        !isPriceZero ?
                                                            '(' +
                                                            utils.formatPrice(service.price) +
                                                            ' ' + label.currency +
                                                            '\u00A0\u00A0' + '×' + '\u00A0\u00A0' +
                                                            service.qty + ' ' + service.measurement +
                                                            ')' :
                                                            ''
                                                    }
                                                </span> :
                                                <></>
                                        }
                                    </span>
                                </div>
                                {
                                    <CountInput
                                        value={service.qty}
                                        onChange={(_currentCount) => {
                                            // props.handle.updateItem(
                                            //     props.product.id,
                                            //     props.product.qty,
                                            //     [{id: serviceId, qty: currentCount}],
                                            // );
                                        }}
                                        max={service.qty ? props.product.stock : 0}
                                        readonly
                                    />
                                }
                            </div>
                        </div>
                );
            })
        }
    </div>;
};

const cmpClass = 'cart-list-services';

const classMap = utils.generateClassMap(cmpClass, {
    row: 'row',
    price: 'price',
    box: 'box',
    item: 'item',
    hidden: 'hidden',
});
