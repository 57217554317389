import utils from 'utils';

import {useState, useMemo, useEffect} from 'react';

import {useLabel} from '@/hooks/export';
import {UiCheckbox} from '@components/export';

export const FilterListItem = (props: {
    filter: any,
    handle: any,
    initShowOptions?: boolean,
    hideQty?: boolean,
    collapsable?: boolean,
}) => {
    const label = useLabel();

    // fixme: to config
    const defaultLimit = 6;
    const maxLimit = 100;

    // todo: add red circle near filter with active checkboxes

    const [displayLimit, setDisplayLimit] = useState(defaultLimit);
    const [showOptions, setShowOptions] = useState(!!props.initShowOptions);

    // fixme: set deps for correct visibility
    const flags = {
        initMany: useMemo(() => props.filter.list.length > defaultLimit, []),
    };

    const handle = {
        toggleShowMore() {
            setDisplayLimit(displayLimit === defaultLimit ? maxLimit : defaultLimit);
        },
        searchFilter(value: string, name: string) {
            props.handle.searchFilter(value, name);

            setDisplayLimit(defaultLimit);
        },
    };

    useEffect(() => {
        setShowOptions(props.initShowOptions);
    }, [props.initShowOptions]);

    return <div className={classMap.item}>
        {
            props.collapsable ?
                <div
                    className={classMap.title}
                    onClick={() => setShowOptions(!showOptions)}
                >
                    <span>{props.filter.label}</span>
                    <i className={
                        showOptions ?
                            'iconfa-arrowUp' :
                            'iconfa-arrowDown'
                    }></i>
                </div> :
                <></>
        }
        <div className={classMap.box + (!showOptions ? ' ' + classMap.boxHidden : '')}>
            {
                flags.initMany ?
                    <div className={classMap.search}>
                        <i className={classMap.iconSearch}></i>
                        <input
                            type="text"
                            placeholder={label.doSearch + '...'}
                            onChange={(e) => handle.searchFilter(e.target.value, props.filter.name)}
                        />
                    </div> :
                    <></>
            }
            <div className={classMap.content}>
                {
                    props.filter.list.map((value: any, index: number) => {
                        if (index < displayLimit) {
                            return <div className={classMap.option} key={index}>
                                <UiCheckbox
                                    onChange={
                                        (checked) => props.handle.selectFilter(checked, props.filter.name, value.name)
                                    }
                                    disabled={!value.qty && !props.hideQty && !value.checked}
                                    label={
                                        <>
                                            {value.label}
                                            {(!props.hideQty ? (' ' + `(${value.qty})`) : '')}
                                        </>
                                    }
                                    checked={value.checked}
                                />
                            </div>;
                        }
                    })
                }
            </div>

            {
                props.filter.list.length > defaultLimit ?
                    <div
                        onClick={() => handle.toggleShowMore()}
                        className={classMap.moreOption}
                    >
                        {
                            displayLimit > defaultLimit ?
                                <span><i className={'iconfa-arrowUp'}></i> {label.showLess}</span> :
                                <span><i className={'iconfa-arrowDown'}></i> {label.showMore}</span>
                        }
                    </div> :
                    <></>
            }
        </div>
    </div>;
};

const cmpClass = 'filter-list-item';
const classMap = utils.generateClassMap(cmpClass, {
    box: 'box',
    boxHidden: 'box--hidden',
    item: 'item',
    title: 'title',
    content: 'content',
    option: 'option',
    moreOption: 'more-option',
    input: 'input',
    search: 'search',
    iconSearch: 'icon-search iconfa-search',
});
