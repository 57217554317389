import utils from 'utils';

import {ProductBadge, UiPopover} from '@components/export';
import {useUiPopover} from '@/hooks/export';

export const ProductStockInfo = (props: {
    stockInfo: any, // fixme: any
    stock: any, // fixme: any
    showAsPopover?: boolean,
}) => {
    const infoJsx = props.stockInfo?.locations !== undefined ?
        Object.entries(props.stockInfo.locations)
            .map(([locationId, location]: [string, { label: string, qty: number, measurement: string }]) => {
                return <p key={locationId}>
                    <span><b>{location.label}</b>: </span> <span>{location.qty} {location.measurement}</span>
                </p>;
            }) :
        <></>;

    const popoverData = useUiPopover();

    return <div className={cmpClass}>
        <div className={classMap.status}>
            <ProductBadge stock={props.stock} hideDiscount/>
            {
                props.showAsPopover ?
                    <i
                        className={classMap.iconInfo + ' iconfa-info'}
                        onClick={popoverData.handleClick}
                    ></i> :
                    <></>
            }
        </div>
        {
            props.showAsPopover ?
                <UiPopover
                    anchorEl={popoverData.anchorEl}
                    setAnchorEl={popoverData.setAnchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: -10,
                    }}
                >
                    <div className={classMap.detail}>
                        {infoJsx}
                    </div>
                </UiPopover> :
                <div className={classMap.detail}>
                    {infoJsx}
                </div>
        }
    </div>;
};

const cmpClass = 'product-stock-info';

const classMap = utils.generateClassMap(cmpClass, {
    detail: 'detail',
    status: 'status',
    iconInfo: 'icon-info',
});
