import utils from 'utils';

import {useContext, useEffect} from 'react';

import useMediaQuery from '@mui/material/useMediaQuery';

import {UiBadge, ProductsGridSort} from '@components/export';
import {useLabel} from '@/hooks/export';
import appContext from '@/appContext.tsx';

export const ProductsControlBar = (props: {
    handle: any,
}) => {
    const label = useLabel();
    const isDesktop = useMediaQuery('(min-width: 1024px)');

    const {filters, viewMode, showFilters, setShowFilters} = useContext(appContext).productsContext;

    useEffect(() => {
        if (!isDesktop || viewMode !== 'list') {
            setShowFilters(true);
        }
    }, [isDesktop, viewMode]);

    return <div className={cmpClass}>
        <div className={classMap.box}>
            <div className={classMap.titleBox}>
                <div className={classMap.title}>
                    <span>
                        {label.searchResults}
                    </span>
                </div>
                <div className={classMap.selectedFilters}>
                    {
                        filters.attributes !== undefined && filters.attributes.length ?
                            filters.attributes.map((item, index) => {
                                if (item.name === 'common') {
                                    return <UiBadge
                                        title={getAttributeLabelByName(item.value)}
                                        onClick={() => props.handle.selectFilter(item.name, item.value)}
                                        key={index}
                                    />;
                                }

                                return <UiBadge
                                    title={getAttributeLabelByName(item.name) + ': ' + item.value}
                                    onClick={() => props.handle.selectFilter(item.name, item.value)}
                                    key={index}
                                />;
                            }) :
                            <></>
                    }
                </div>
            </div>
            <div className={classMap.actions}>
                {
                    isDesktop && viewMode === 'list' ?
                        <span
                            className={classMap.toggleFilters}
                            onClick={() => setShowFilters(!showFilters)}
                        >
                            {
                                showFilters ?
                                    <><i className={'iconfas-filter'}/>{label.hideFilters}</> :
                                    <><i className={'iconfa-filter'}/>{label.setFilters}</>
                            }
                        </span> : <></>
                }
                {
                    (filters.attributes !== undefined && filters.attributes.length) || filters.name || filters.purpose ?
                        <span
                            onClick={() => props.handle.cleanFilters()}
                            className={classMap.cleanFilters}
                        >
                            <i className={'iconfa-close'}></i>{label.cancelFilters}
                        </span> : <></>
                }
            </div>
        </div>
        {
            viewMode === 'grid' ?
                <ProductsGridSort/> :
                <></>
        }
        {
            isDesktop ? <>
                <div
                    className={classMap.toggleViewBtn}
                    onClick={() => props.handle.toggleViewMode()}
                >
                    <i className={viewMode === 'grid' ? 'iconfas-grid' : 'iconfas-list'}/>
                </div>
            </> : <></>
        }
    </div>;
};

const cmpClass = 'products-control-bar';
const classMap = utils.generateClassMap(cmpClass, {
    box: 'box',
    cleanFilters: 'clean-filters',
    title: 'title',
    selectedFilters: 'selected-filters',
    toggleViewBtn: 'toggle-view-btn',
    toggleFilters: 'toggle-filters',
    actions: 'actions',
});

const getAttributeLabelByName = (
    name: string,
) => {
    // fixme! dehardcode - get this data from api, but not directly
    //  - write it to context somewhere before - thus this method will not be needed

    // ? write to local state

    const attrLabelMap = {
        'Typ_pneu': 'Typ pneu',
        'Druh_stroje': 'Druh stroje',
        'Brand': 'Výrobce',
        'Prumer_rafku': 'Průměr ráfku',
        'Rozmer_pneu': 'Rozměr pneu',
        'Rim_pocet_platen': 'RIM / Počet pláten',
        'Pocet_platen': 'Počet pláten',
        'Pocet_platen_index': 'Počet pláten/Index',
        'Profil_kola': 'Profil kola',
        'Prumer_kola': 'Průměr kola',
        'Sirka_kola': 'Šířka kola',
        'Nosnost': 'Nosnost',
        'Sirka': 'Šířka',
        'Delka': 'Délka',
        'Trida_iso': 'Třída ISO',
        'Tloustka': 'Tloušťka',
        'Typ_zajisteni': 'Typ zajištění',
        'isInStock': 'Skladem',
        'isDiscount': 'Akce',
        'isSale': 'Výprodej',
    };

    return attrLabelMap[name];
};
